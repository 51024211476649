import './Text.scss'

import cn from 'classnames'

export type IProps = {
  children: React.ReactNode
  align?:
    | 'start'
    | 'center'
    | 'inherit'
    | 'initial'
    | 'unset'
    | 'end'
    | 'justify'
    | 'left'
    | 'right'
  tag?: 'p' | 'span' | 'div' | 'li'
  color?: 'default' | 'inherit'
  size?: 'xs' | 's' | '2s' | '3s' | 'm' | 'l' | 'xl'
  weight?: 'm' | 'l' | 'xl' | '2xl' | 'default'
  className?: string
  ellipsisRows?: 'two' | 'three'
  ellipsisHeightProperty?: 'height' | 'max-height'
  html?: string
}

const Text = ({
  children,
  className,
  tag = 'p',
  color = 'inherit',
  size = 'm',
  align = 'inherit',
  weight = 'default',
  ellipsisRows,
  ellipsisHeightProperty = 'max-height',
  html,
}: IProps) => {
  const classes = `${cn(
    'text',
    `text--color-${color}`,
    `text--size-${size}`,
    `text--align-${align}`,
    `text--weight-${weight}`,
    {
      [`${className}`]: !!className,
      [`text--ellipsis-rows-${ellipsisRows}`]: !!ellipsisRows,
      [`text--ellipsis-height-prop-${ellipsisHeightProperty}`]: !!ellipsisRows,
    },
  )}`

  const Tag = tag

  return (
    <>
      {html ? (
        <Tag dangerouslySetInnerHTML={{ __html: html }} className={classes}></Tag>
      ) : (
        <Tag className={classes}>{children}</Tag>
      )}
    </>
  )
}

export default Text

export const DEFAULT_TITLE = '4tuna'
export const DEFAULT_DESCRIPTION =
  "An award-winning casino solutions provider, we're here to transform your online and offline gaming experience with our exceptional slots. Indulge in fun, fair, and secure gaming with 80+ reeled games. Play on any device, using fiat or crypto."
export const SITE_NAME = '4tuna.games'
export const COMPANY_NAME = '4tuna'
export const AUTHOR = 'Dev Family Team'

export const SESSION_TOKEN_KEY_NAME = '4tuna_session_token'
export const SESSION_TOKEN_PLAYTEST_KEY_NAME = 'playtest_session_token'
export const CHANGE_LOCALE_KEY_NAME = '4tuna_is_change_locale'

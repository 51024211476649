'use client'

import './Button.scss'

import cn from 'classnames'
import dynamic from 'next/dynamic'
import ClipLoader from 'react-spinners/ClipLoader'

import { IPathname } from '@/src/app/config/pathnames'
import { ColorSemanticThird } from '@/src/shared/config/variables'

import { Icon } from '../Icon'
import { LocalizedLink } from '../LocalizedLink'

const FileDownloader = dynamic(() => import('../FileDownloader').then((mod) => mod.FileDownloader))

export type IButton = {
  children?: React.ReactNode
  className?: string
  size?: '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl'
  variant?: 'primary' | 'secondary' | 'third' | 'fourth' | 'gradient'
  href?: IPathname
  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit'
  iconName?: string
  external?: boolean
  squared?: boolean
  roundedCorners?: boolean
  tag?: 'button' | 'div'
  download?: boolean
  onClick?: () => void
}

const Button = ({
  className,
  children,
  size = 'm',
  variant = 'primary',
  href,
  iconName,
  disabled = false,
  loading = false,
  external = false,
  squared = false,
  roundedCorners = false,
  type = 'button',
  tag = 'button',
  download = false,
  onClick = () => {},
}: IButton) => {
  const classes = cn('button', `button--size-${size}`, `button--variant-${variant}`, {
    'button--disabled': disabled,
    'button--loading': loading,
    'button--is-squared': squared,
    'button--is-rounded-corners': roundedCorners,
    [`${className}`]: !!className,
  })

  const elText = (
    <>
      {iconName && squared && <Icon className={iconName} name={iconName} width="26" height="26" />}
      {loading && (
        <i className="button__loader">
          <ClipLoader
            cssOverride={{ borderWidth: '4px' }}
            speedMultiplier={1.5}
            size={30}
            color={ColorSemanticThird}
          />
        </i>
      )}
      <span>{children}</span>
    </>
  )

  if (download && href) {
    return (
      <FileDownloader className={classes} url={href}>
        {elText}
      </FileDownloader>
    )
  }

  const Tag = tag

  const handleClick = (e: React.SyntheticEvent) => {
    if (disabled) {
      e.preventDefault()
      return
    }

    onClick()
  }

  const el = (
    <Tag onClick={(e) => handleClick(e)} className={classes} type={type}>
      {elText}
    </Tag>
  )

  const target = external ? '_blank' : '_self'

  return (
    <>
      {href ? (
        <LocalizedLink className={classes} href={href} target={target}>
          {elText}
        </LocalizedLink>
      ) : (
        <>{el}</>
      )}
    </>
  )
}

export default Button

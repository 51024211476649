'use client'

import './LanguageSwitcher.scss'

import classNames from 'classnames'
import { setCookie } from 'cookies-next'
import Link from 'next/link'
import { useLocale } from 'next-intl'
import { useRef, useState } from 'react'
import { useKey } from 'react-use'

import { CHANGE_LOCALE_KEY_NAME } from '@/src/app/config/constants'
import { locales } from '@/src/app/config/pathnames'
import useClickAway from '@/src/shared/hooks/useClickAway'
import { useFullUrl } from '@/src/shared/hooks/useFullUrl'
import { Icon } from '@/src/shared/ui/Icon'

const LanguageSwitcher = () => {
  const currentLocale = useLocale()
  const { fullUrl } = useFullUrl()

  const [isOpen, setDropState] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  useClickAway(() => {
    setDropState(false)
  }, ref)

  useKey('Escape', () => setDropState(false))

  const _locales = locales.filter((locale) => locale !== currentLocale)

  const onChangeLang = () => {
    setCookie(CHANGE_LOCALE_KEY_NAME, true)
  }

  return (
    <section
      ref={ref}
      className={classNames('language-switcher', {
        'language-switcher--is-open': isOpen,
      })}
    >
      <button className="language-switcher__toggler" onClick={() => setDropState(() => !isOpen)}>
        <span className="language-switcher__toggler-text">{currentLocale}</span>
        <Icon
          width={14}
          height={12}
          className="language-switcher__toggler-icon"
          name="arrow-down-filled-icon"
        />
      </button>

      <div className="language-switcher__body">
        <div className="language-switcher__body-inner">
          <ul className="language-switcher__list">
            {_locales.map((lng) => (
              <li key={lng} className="language-switcher__list-item">
                <Link
                  scroll={false}
                  href={`/${lng}/${fullUrl}`}
                  className="language-switcher__list-link"
                  onClick={onChangeLang}
                >
                  {lng}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default LanguageSwitcher

/* eslint-disable @typescript-eslint/no-explicit-any */
import './LocalizedLink.scss'

import { ParsedUrlQueryInput } from 'querystring'

import { Link } from '@/src/app/config/i18n'
import { IPathname } from '@/src/app/config/pathnames'

type IPathnameObj = {
  href?: string | null | undefined
  pathname?: IPathname
  query?: string | null | ParsedUrlQueryInput
  params?: ParsedUrlQueryInput | null
}

export type ILocalizedLink = {
  children?: React.ReactNode
  className?: string
  href: IPathname | IPathnameObj
  target?: '_blank' | '_self'
  onClick?: (e: React.MouseEvent) => void
  scroll?: boolean
}

const LocalizedLink = ({
  className = '',
  href,
  target,
  scroll = true,
  onClick,
  ...rest
}: ILocalizedLink) => {
  return (
    <Link
      scroll={scroll}
      onClick={onClick}
      href={href as any}
      target={target}
      className={className}
      {...rest}
    />
  )
}

export default LocalizedLink

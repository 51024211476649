import { Pathnames } from 'next-intl/routing'

import { ILang } from '../model/lang'

export const defaultLocale: ILang = 'en'

export const locales: ILang[] = [defaultLocale, 'es', 'ru']
export const localePrefix = 'always'

export const pathnames = {
  '/': '/',

  '/games': {
    en: '/games',
    es: '/games',
    ru: '/games',
  },

  '/games/[slug]': {
    en: '/games/[slug]',
    es: '/games/[slug]',
    ru: '/games/[slug]',
  },

  '/store': {
    en: '/store',
    es: '/store',
    ru: '/store',
  },

  '/store/[slug]': {
    en: '/store/[slug]',
    es: '/store/[slug]',
    ru: '/store/[slug]',
  },

  '/studio': {
    en: '/studio',
    es: '/studio',
    ru: '/studio',
  },

  '/studio/careers': {
    en: '/studio/careers',
    es: '/studio/careers',
    ru: '/studio/careers',
  },

  '/studio/manifesto': {
    en: '/studio/manifesto',
    es: '/studio/manifesto',
    ru: '/studio/manifesto',
  },

  '/studio/news': {
    en: '/studio/news',
    es: '/studio/news',
    ru: '/studio/news',
  },

  '/studio/news/[slug]': {
    en: '/studio/news/[slug]',
    es: '/studio/news/[slug]',
    ru: '/studio/news/[slug]',
  },

  '/tournaments': {
    en: '/tournaments',
    es: '/tournaments',
    ru: '/tournaments',
  },
  '/tg-app': {
    en: '/tg-app',
    es: '/tg-app',
    ru: '/tg-app',
  },
  '/tg-app/leaderboard': {
    en: '/tg-app/leaderboard',
    es: '/tg-app/leaderboard',
    ru: '/tg-app/leaderboard',
  },
} satisfies Pathnames<typeof locales>

export type IPathname = keyof typeof pathnames
